<a (click)="open(content)" class="btn btn-success btn-whatsapp" [class]="config.css" >
  <i class='bx bxl-whatsapp'></i>
  {{config.text ?? 'Consultar'}}
</a>

<ng-template #content let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title" [innerText]="config.title"></h5>
    </div>
    <div class="modal-body">
      <p [innerText]="config.description"> </p>
    </div>
    <div class="modal-footer">
      <button *ngFor="let btn of buttons" type="button" class="btn {{btn.class}}" (click)="btn.action()" [innerText]="btn.text"></button>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>
