<ul dndDropzone (dndDragover)="onDragover($event)" [dndHorizontal]="true"
    (dndDrop)="onDrop($event, items)" class="images">


  <li dndPlaceholderRef class="dndPlaceholder"></li>
  <li [dndDraggable]="img" *ngFor="let img of items; let idx = index" class="box-image"
      [dndEffectAllowed]="img.effectAllowed" (dndMoved)="onDraggableMoved($event, items, img)"
      (dndEnd)="onDragEnd($event, items)">
    <div dndDragImageRef>
      <img [src]="img.content.realpath" width="150" height="150">
    </div>
  </li>

</ul>
