import { Component, OnInit } from '@angular/core';
import {baseUrlImg, baseUrlPublic} from '../../../../environments/environment';
@Component({
  selector: 'app-card-home',
  templateUrl: './card-home.component.html',
  styleUrls: ['./card-home.component.scss']
})
export class CardHomeComponent implements OnInit {
  banners:Array<any>;
  baseUrlImg = baseUrlImg;
  baseUrlPublic = baseUrlPublic;

  constructor() {
  }

  ngOnInit(): void {

    this.banners= [
      {
        "id": 8,
        "orden": 1,
        "titulo": "CAPACITACIONES POST GRADO",
        "tipo": "HOME_CATEGORIAS",
        "width":"85",
        "imagen": "/assets/icons/capacitaciones.png",
        "link": baseUrlPublic + "courses",
        "externo": false,
        "descripcion": "Defina su objetivo y encuentre la capacitación que esta buscando"
      },
      {
        "id": 7,
        "orden": 2,
        "titulo": "PRODUCTOS",
        "tipo": "HOME_CATEGORIAS",
        "width":"60",
        "imagen": "/assets/icons/compras.png",
        "link": baseUrlPublic + "product",
        "externo": false,
        "descripcion": "Consiga los Insumos que necesita al mejor precio"
      },
      {
        "id": 11,
        "orden": 5,
        "titulo": "SERVICIOS TERCERIZADOS",
        "tipo": "HOME_CATEGORIAS",
        "width":"90",
        "imagen": "/assets/icons/Servicios_Negro.png",
        "link": baseUrlPublic + "providers/all",
        "externo": false,
        "descripcion": "Encuentre la indumentaria odontológica que esta necesitando"
      },
      {
        "id": 54,
        "orden": 11,
        "titulo": "LINKS UTILES",
        "tipo": "HOME_CATEGORIAS",
        "width":"65",
        "imagen": "/assets/icons/links.png",
        "link": baseUrlPublic + "helpful-links?categoria=2&nombre=CANALES%20DE%20YOUTUBE",
        "externo": false,
        "descripcion": "En este lugar encontrara los canales de YouTube en Odontología que le pueden ser de utilidad"
      },
    ]
  }

}
