import {RouteInfo} from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {path: '/', params: {}, title: 'Inicio', class: 'empty-class', icon: 'bx bx-home',  submenu: []},
  {path: '/about-us', params: {}, title: 'Sobre Nosotros', class: 'empty-class', icon: 'bx bx-user-voice',  submenu: []},
  {path: '', params: {}, title: 'Capacitación', icon: 'lni lni-graduation', class: 'sub',  submenu: [
      {path: '/courses', params: {}, title: 'Cursos', icon: '',  submenu: []},
      {path: '/helpful-links', params: {categoria: 5, nombre: 'BIBLIOTECAS'}, title: 'Bibliotecas', icon: '',  submenu: []},
      {path: '/helpful-links', params: {categoria: 14, nombre: 'VIVOS DE INSTAGRAM'}, title: 'Vivos de Instagram', icon: '',  submenu: []},
    ]
  },
  {path: '', params: {}, title: 'Compras', icon: 'lni lni-cart', class: 'sub',  submenu: [
      {path: '/product', params: {}, title: 'Ver todo', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 3, nombre: 'Insumos'}, title: 'Insumos', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 4, nombre: 'Instrumental'}, title: 'Instrumental', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 1, nombre: 'Equipamiento'}, title: 'Equipamiento', icon: '',  submenu: []},
      {path: '/product', params: {precio: 1, nombre: 'Con precio'}, title: 'Con precio', icon: '',  submenu: []},
      {path: '/helpful-links', params: {categoria: 10, nombre: 'INDUMENTARIA'}, title: 'Indumentaria', icon: '',  submenu: []},
      {path: '/helpful-links', params: {categoria: 11, nombre: 'SOFTWARE'}, title: 'Software', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 7, nombre: 'Libros'}, title: 'Libros', icon: '',  submenu: []},
      {path: '/coming-soon', params: {categoria: 3, nombre: 'Usados'}, title: 'Usados', icon: '',  submenu: []},
    ]
  },
  {path: '', params: {}, title: 'OFERTAS', icon: 'lni lni-offer', class: 'sub',  submenu: [
      {path: '/product', params: {oferta: 1}, title: 'Ver todo', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 3, oferta: 1 , nombre: 'Insumos'}, title: 'Insumos', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 4, oferta: 1, nombre: 'Instrumental'}, title: 'Instrumental', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 1, oferta: 1, nombre: 'Equipamiento'}, title: 'Equipamiento', icon: '',  submenu: []},
    ]
  },
  {path: '', params: {}, title: 'Nuevos lanzamientos', icon: 'lni lni-bolt-alt', class: 'sub',  submenu: [
      {path: '/product', params: {nuevo: 1}, title: 'Ver todo', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 3, nuevo: 1 , nombre: 'Insumos'}, title: 'Insumos', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 4, nuevo: 1, nombre: 'Instrumental'}, title: 'Instrumental', icon: '',  submenu: []},
      {path: '/product', params: {categoria: 1, nuevo: 1, nombre: 'Equipamiento'}, title: 'Equipamiento', icon: '',  submenu: []},
    ]
  },
  {path: '', params: {}, title: 'Servicios Tercerizados', icon: 'lni lni-users', class: 'sub',  submenu: [
      {path: '/providers/all', params: {}, title: 'Todos', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Odontólogos', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Secretarias', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Asistentes Dentales', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Mecanicos Dentales', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Laboratorios dentales DIGITALES y CAD-CAM', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Diagnóstico por imágenes en odontología', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Técnicos en Reparación en Odontología', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Diseñadores de interiores', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Community Manager', icon: '',  submenu: []},
    ]
  },
  {path: '', params: {}, title: 'Links útiles ', icon: 'lni lni-link', class: 'sub',  submenu: [
      {path: '/helpful-links', params: {categoria: 2, nombre: 'CANALES DE YOUTUBE'}, title: 'Canales de YouTube', icon: '',  submenu: []},
      {path: '/helpful-links', params: {categoria: 3, nombre: 'BLOGS INFORMATIVOS'}, title: 'Blogs', icon: '',  submenu: []},
      {path: '/helpful-links', params: {categoria: 5, nombre: 'BIBLIOTECAS'}, title: 'Bibliotecas', icon: '',  submenu: []},
      {path: '/helpful-links', params: {categoria: 14, nombre: 'VIVOS DE INSTAGRAM'}, title: 'Vivos de Instagram', icon: '',  submenu: []},
    ]
  },
  {path: '', params: {}, title: 'Gestión de consultorios', icon: 'lni lni-customer', class: 'sub',  submenu: [
      {path: '/coming-soon', params: {}, title: 'Información de valor', icon: '',  submenu: []},
      {path: '/coming-soon', params: {}, title: 'Información para gestión', icon: '',  submenu: []},
    ]
  },

  {
    path: '', params: {}, title: 'Apps odontologicas', icon: 'lni lni-mobile', class: 'sub', submenu: [
      {
        path: '/helpful-links', params: {categoria: 8, nombre: 'Links to PlayStore'},
        title: 'Links to PlayStore (Android)', icon: '', submenu: []
      },
      {
        path: '/helpful-links', params: {categoria: 9, nombre: 'Links to AppStore'},
        title: 'Links to AppStore (iOS/iPhone)', icon: '', submenu: []
      },
      {
        path: '/helpful-links', params: {categoria: 11, nombre: 'Software para PC'},
        title: 'Software para PC', icon: '', submenu: []
      },
    ]
  },

  {path: '', params: {}, title: 'Contacto', icon: 'lni lni-envelope', class: 'sub',  submenu: [
      {path: '/contacts', params: {}, title: 'Contáctenos', icon: '',  submenu: []},
      {path: '/faqs', params: {}, title: 'Preguntas frecuentes', icon: '',  submenu: []},
      {path: '/feedback', params: {}, title: 'Feedback', icon: '',  submenu: []},
    ]
  },
];

