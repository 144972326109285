import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isMobile} from "../utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

export class SendWhatsppConfig {
  title: string;
  description: string;
  text?: string;
  message: string;
  phone: string;
  callback?: Function;
  css?: string;
}

@Component({
  selector: 'app-send-whatsapp',
  templateUrl: './send-whatsapp.component.html',
  styleUrls: ['./send-whatsapp.component.scss']
})
export class SendWhatsappComponent implements OnInit {

  @Input() config: SendWhatsppConfig;
  @Output() destroy = new EventEmitter<boolean>();
  @Output() submit = new EventEmitter<boolean>();


  buttons: {text: string, action: Function, class: string}[] = [];
  max = 4096; // https://stackoverflow.com/questions/63266960/what-is-the-max-char-limit-for-the-text-parameter-in-whatsapp-send-text-urls

  constructor(
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
  }

  openMobile(): void {
    const url = `https://wa.me/${this.config.phone}?text=${window.encodeURIComponent(this.config.message).substring(0, this.max)}`;
    window.open(url, '_blank');

    setTimeout(() => {
      this.config.callback && this.config.callback();
      this.destroy.emit(true);
      this.modalService.dismissAll();
    }, 2000)
  }

  openDesktop(): void {
    console.log(this.config.message, 'message')
    const url = `https://web.whatsapp.com/send?phone=${this.config.phone}&text=${window.encodeURIComponent(this.config.message).substring(0, this.max)}`;
    window.open(url, '_blank');
    setTimeout(() => {
      this.config.callback && this.config.callback();
      this.destroy.emit(true);
      this.modalService.dismissAll();
    }, 2000)
  }

  cancel(): void {
    this.modalService.dismissAll();
    this.destroy.emit(true);
  }

  open(content: any): void {
    const mobile = isMobile();

    this.buttons = [];

    this.buttons.push(
      {text: 'Cancelar', class: 'btn-light', action: this.cancel.bind(this)},
      {text: 'Abrir Aplicación', class: 'btn-outline-primary', action: this.openMobile.bind(this)},
    )

    if(!mobile) {
      this.buttons.push(
        {text: 'Abrir Web', class: 'btn-outline-primary', action: this.openDesktop.bind(this)},
      )
    }


    this.modalService.open(content).result;
  }




}
