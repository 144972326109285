<ng-container *ngIf="uuid">
  <div class="accordion" [id]="'sidebarAccordion'+uuid">

    <div class="accordion-item">
      <h2 class="accordion-header" [id]="'filterHeading'+uuid" (click)="expanded = !expanded">
      <span
        class="cursor-pointer accordion-button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#filterCollapse'+uuid"
        aria-expanded="true"
        [attr.aria-controls]="'filterCollapse'+uuid">
          <i *ngIf="checkmark" style="min-width: 20px" class="lni mx-2" [class.lni-checkmark-circle]="!!expanded"></i>
          {{name}}
      </span>
      </h2>
      <div [id]="'filterCollapse' + uuid" class="accordion-collapse collapse" [class.show]="!!expanded"
           [attr.aria-labelledby]="'filterHeading'+uuid" [attr.data-bs-parent]="'#sidebarAccordion'+uuid">
        <div class="content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-container>
