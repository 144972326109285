import {Component, Input, OnInit} from '@angular/core';
import {DndDropEvent} from "ngx-drag-drop";

@Component({
  selector: 'app-odonto-sort-images',
  templateUrl: './odonto-sort-images.component.html',
  styleUrls: ['./odonto-sort-images.component.scss']
})
export class OdontoSortImagesComponent implements OnInit {

  @Input() items: any[];

  constructor() { }

  ngOnInit(): void {
  }

  onDragover(event: DragEvent): void {
    // console.log('dragover', JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent, list: any[]): void {
    let index = event.index;
    console.log(event);
    if (typeof index === 'undefined') {
      index = list.length;
    }

    list.splice(index, 0, event.data);
  }

  onDragEnd(event: DragEvent, imagenes: any[]): void {
  }


  onDraggableMoved(event: DragEvent, list: any[], item: any): void {
    const index = list.indexOf(item);
    list.splice(index, 1);
  }
}
