import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-iframe-google-map',
  templateUrl: './iframe-google-map.component.html',
  styleUrls: ['./iframe-google-map.component.scss']
})
export class IframeGoogleMapComponent implements OnInit {

  apiKey = environment.firebaseConfig.apiKey;
  @Input() lat: string;
  @Input() lng: string;

  constructor() { }

  ngOnInit(): void {
  }

}
